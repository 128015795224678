import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AboutBanner from "../images/About-Banner.jpg";
import { NavLink, Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import AboutImg from "../images/about_01.jpg";
const Sitemap = () => {
  return (
    <React.Fragment>
      <section className="jumbotron  position-relative">
        <img src={AboutBanner} alt="About" className="img-fluid w-100" />
        <div className="position-absolute top-50 start-50 translate-middle ">
          <div className="banner">
            <h2>
              <span>Sitemap</span>
            </h2>
            <ul className="page-title-link">
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>
                <NavLink to="/Sitemap">Siemap</NavLink>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <Container className="py-4">
        <Row className=" d-flex align-items-center justify-content-center">
          <Col md="6">
            <Nav className="flex-column">
              <Nav.Link as={Link} to="/About" eventKey="/About">
                About
              </Nav.Link>
              <Nav.Link as={Link} to="/Menu" eventKey="/Menu">
                Menu
              </Nav.Link>

              <Nav.Link as={Link} to="/Reviews" eventKey="/Reviews">
                Reviews
              </Nav.Link>

              <Nav.Link as={Link} to="/Catering" eventKey="/Catering">
                Catering
              </Nav.Link>
              <Nav.Link as={Link} to="/Contact" eventKey="/Contact">
                Contact
              </Nav.Link>
            </Nav>
          </Col>

          <Col md="6">
            <img src={AboutImg} alt="" className="img-fluid" />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Sitemap;
