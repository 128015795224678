import React, { useEffect } from "react";
import Chaats from "./MOCK_DATA.json";
import SouthIndian from "./SouthIndian.json";
import Entrees from "./Entrees.json";
import SwatiSpecials from "./SwatiSpecials.json";
import MiniMeals from "./MiniMeals.json";
import Parathas from "./Parathas.json";
import LLR from "./LLR.json";
import Breads from "./Breads.json";
import Desserts from "./Desserts.json";
import Beverages from "./Beverages.json";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import AboutBanner from "../images/About-Banner.jpg";

const Menu = () => {
  useEffect(() => {
    document.title = "Menu - Uruswati";
  }, []);
  return (
    <>
      <section className="jumbotron  position-relative mb-4">
        <img src={AboutBanner} alt="About" className="img-fluid w-100" />
        <div className="position-absolute top-50 start-50 translate-middle ">
          <div className="banner">
            <h2>
              <span>Menu</span>
            </h2>
            <ul className="page-title-link">
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/">Menu</a>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <Container>
        <Row>
          <Col lg="12">
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Row>
                <Col sm={3} className="mb-4">
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Chaats & Snacks</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">South Indian</Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link eventKey="third">Entrees</Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link eventKey="forth">Swati Specials</Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link eventKey="five">Mini Meals</Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link eventKey="six">Parathas</Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link eventKey="seven">
                        Lentils, Legumes And Rice
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="eight">Breads</Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link eventKey="nine">Desserts</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="ten">Beverages</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col sm={9}>
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      <Row>
                        <Col lg="12">
                          <h2>Chaats</h2>
                        </Col>
                      </Row>

                      <Row className="row-cols-1 row-cols-lg-2">
                        {Chaats.map((post, item) => (
                          <Col key={item}>
                            <div key={post.id}>
                              <div className="menu_content">
                                <h5>
                                  {post.Chaats} <span>{post.price}</span>
                                </h5>
                                <p>{post.details}</p>
                              </div>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </Tab.Pane>

                    <Tab.Pane eventKey="second">
                      <Row>
                        <Col lg="12">
                          <h2>South Indian</h2>
                          <p>ALL DISHES SERVED With SAMBHAR & CHUTNEY.</p>
                        </Col>
                      </Row>
                      <Row className="row-cols-1 row-cols-lg-2">
                        {SouthIndian.map((post, item) => (
                          <Col key={item}>
                            <div key={post.id}>
                              <div className="menu_content">
                                <h5>
                                  {post.southindian} <span>{post.price}</span>
                                </h5>
                                <p>{post.details}</p>
                              </div>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </Tab.Pane>

                    <Tab.Pane eventKey="third">
                      <Row>
                        <Col lg="12">
                          <h2>Entrees</h2>
                          <p>SERVED WITH BASMATI RICE, RAITA & PICKLE.</p>
                        </Col>
                      </Row>
                      <Row className="row-cols-1 row-cols-lg-2">
                        {Entrees.map((post, item) => (
                          <Col key={item}>
                            <div key={post.id}>
                              <div className="menu_content">
                                <h5>
                                  {post.entrees} <span>{post.price}</span>
                                </h5>
                                <p>{post.details}</p>
                              </div>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </Tab.Pane>

                    <Tab.Pane eventKey="forth">
                      <Row>
                        <Col lg="12">
                          <h2>Swati Specials</h2>
                        </Col>
                      </Row>
                      <Row className="row-cols-1 row-cols-lg-2">
                        {SwatiSpecials.map((post, item) => (
                          <Col key={item}>
                            <div key={post.id}>
                              <div className="menu_content">
                                <h5>
                                  {post.swatispecial} <span>{post.price}</span>
                                </h5>
                                <p>{post.details}</p>
                              </div>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </Tab.Pane>

                    <Tab.Pane eventKey="five">
                      <Row>
                        <Col lg="12">
                          <h2>Mini Meals</h2>
                        </Col>
                      </Row>
                      <Row className="row-cols-1 row-cols-lg-2">
                        {MiniMeals.map((post, item) => (
                          <Col key={item}>
                            <div key={post.id}>
                              <div className="menu_content">
                                <h5>
                                  {post.minimeals} <span>{post.price}</span>
                                </h5>
                                <p>{post.details}</p>
                              </div>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </Tab.Pane>

                    <Tab.Pane eventKey="six">
                      <Row>
                        <Col lg="12">
                          <h2>Parathas</h2>
                          <p>
                            SERVED WITH DAL, RAITA & PICKLE. PARATHAS ARE FLAT
                            WHOLE WHEAT BREADS EITHER W. A STUFFING OR PLAIN.
                          </p>
                        </Col>
                      </Row>
                      <Row className="row-cols-1 row-cols-lg-2">
                        {Parathas.map((post, item) => (
                          <Col key={item}>
                            <div key={post.id}>
                              <div className="menu_content">
                                <h5>
                                  {post.parathas} <span>{post.price}</span>
                                </h5>
                                <p>{post.details}</p>
                              </div>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </Tab.Pane>

                    <Tab.Pane eventKey="seven">
                      <Row>
                        <Col lg="12">
                          <h2>Lentils, Legumes And Rice</h2>
                        </Col>
                      </Row>
                      <Row className="row-cols-1 row-cols-lg-2">
                        {LLR.map((post, item) => (
                          <Col key={item}>
                            <div key={post.id}>
                              <div className="menu_content">
                                <h5>
                                  {post.llr} <span>{post.price}</span>
                                </h5>
                                <p>{post.details}</p>
                              </div>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </Tab.Pane>

                    <Tab.Pane eventKey="eight">
                      <Row>
                        <Col lg="12">
                          <h2>Breads</h2>
                        </Col>
                      </Row>
                      <Row className="row-cols-1 row-cols-lg-2">
                        {Breads.map((post, item) => (
                          <Col key={item}>
                            <div key={post.id}>
                              <div className="menu_content">
                                <h5>
                                  {post.breads} <span>{post.price}</span>
                                </h5>
                                <p>{post.details}</p>
                              </div>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </Tab.Pane>

                    <Tab.Pane eventKey="nine">
                      <Row>
                        <Col lg="12">
                          <h2>Desserts</h2>
                        </Col>
                      </Row>
                      <Row className="row-cols-1 row-cols-lg-2">
                        {Desserts.map((post, item) => (
                          <Col key={item}>
                            <div key={post.id}>
                              <div className="menu_content">
                                <h5>
                                  {post.desserts} <span>{post.price}</span>
                                </h5>
                                <p>{post.details}</p>
                              </div>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </Tab.Pane>

                    <Tab.Pane eventKey="ten">
                      <Row>
                        <Col lg="12">
                          <h2>Beverages</h2>
                        </Col>
                      </Row>
                      <Row className="row-cols-1 row-cols-lg-2">
                        {Beverages.map((post, item) => (
                          <Col key={item}>
                            <div key={post.id}>
                              <div className="menu_content">
                                <h5>
                                  {post.beverages} <span>{post.price}</span>
                                </h5>
                                <p>{post.details}</p>
                              </div>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Col>
        </Row>

        {/* <Row>
          <Col lg="3">Side Menu Bar</Col>
          <Col lg="9">
            <Row className="row-cols-1 row-cols-lg-2">
              {Data.map((post, item) => (
                <Col key={item}>
                  <div key={post.id}>
                    <div className="menu_content">
                      <h4>
                        {post.Chaats} <span>$24</span>
                      </h4>
                      <p>{post.details}</p>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row> */}
      </Container>
    </>
  );
};

export default Menu;
