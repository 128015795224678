/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { NavLink } from "react-router-dom";

import "./Footer.css";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <React.Fragment>
      <footer className="footer">
        <Container>
          <Row>
            <Col lg="5">
              <h4>About Uru Swati</h4>
              <p>
                Uru-Swati meaning the “Morning star of peace” is a family
                oriented comfortable, 14-table North and South Indian full
                service vegetarian restaurant serving food that is fresh and of
                the highest quality. Read More
              </p>
            </Col>
            <Col lg="4">
              <h4>Useful links</h4>

              <Row>
                <Col>
                  <ul className="footer-links">
                    <li>
                      <NavLink to="/About" onClick={scrollToTop}>
                        About
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/Menu" onClick={scrollToTop}>
                        Menu
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/Reviews" onClick={scrollToTop}>
                        Reviews
                      </NavLink>
                    </li>
                  </ul>
                </Col>

                <Col>
                  <ul className="footer-links">
                    <li>
                      <NavLink to="/Catering" onClick={scrollToTop}>
                        Catering
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/Contact" onClick={scrollToTop}>
                        Contact
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/Sitemap" onClick={scrollToTop}>
                        Sitemap
                      </NavLink>
                    </li>
                  </ul>
                </Col>
              </Row>
            </Col>
            <Col lg="3">
              <h4>Contact Us</h4>
              <ul className="footer-links">
                <li> Uru Swati Restaurant</li>
                <li>2629 W Devon Avenue, Chicago, IL 60659</li>
                <li>(773) 381-1010 </li>
                <li> Email: uruswati7@gmail.com</li>
              </ul>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
