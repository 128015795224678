/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState } from "react";
import AboutBanner from "../images/About-Banner.jpg";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import axios from "axios";
import cardC from "../images/master-card.jpg";

const Contact = () => {
  //form state
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [msg, setMsg] = useState("");

  //form event

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(name, phone, email, msg);
    const data = {
      Name: name,
      Phone: phone,
      Email: email,
      Msg: msg,
    };
    axios
      .post(
        "https://sheet.best/api/sheets/4fdd13fe-865d-4c66-ad00-61a08bc22040",
        data
      )
      .then((response) => {
        console.log(response);
        setName("");
        setPhone("");
        setEmail("");
        setMsg("");
      });
  };

  return (
    <React.Fragment>
      <section className="jumbotron  position-relative">
        <img src={AboutBanner} alt="About" className="img-fluid w-100" />
        <div className="position-absolute top-50 start-50 translate-middle ">
          <div className="banner">
            <h2>
              <span>Contact</span>
            </h2>
            <ul className="page-title-link">
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/">Contact</a>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <Container className="py-4">
        <Row>
          <Col md="6">
            <div className="contact-details">
              <h3>Contact Details</h3>
              <p className="mb-2">
                <span className="d-block fw-bold">Address</span> 2629 W Devon
                Avenue, Chicago, IL 60659
              </p>
              <p>
                <span className="d-block  fw-bold">Email</span>
                <a href="mailto:uruswati7@gmail.com"> uruswati7@gmail.com</a>
              </p>
              <p>
                <span className="d-block fw-bold">Phone & Fax:</span>
                (773) 381-1010
              </p>
              <p>
                <span className="d-block  fw-bold">Opening Hours:</span>
                <span className="d-block">Monday: 11:30am - 9:30pm </span>
                <span className="d-block"> Wednesday: 11:30am - 9:30pm </span>
                <span className="d-block">Thursday: 11:30am - 9:30pm </span>
                <span className="d-block">Tuesday: Closed </span>
              </p>

              <div>
                <h6>We Accept</h6>
                <div className="mb-4">
                  <img src={cardC} alt="Master Card" />
                </div>

                <Button
                  variant="danger"
                  href="https://orderonlinemenu.com/uruswati"
                  target="_blank"
                >
                  Order Online
                </Button>
              </div>
            </div>
          </Col>

          <Col md="6">
            <h3>Feedback Form</h3>
            <Form autoComplete="off" onSubmit={handleSubmit}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Your Name"
                  required
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput2"
              >
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Phone Number"
                  required
                  onChange={(e) => setPhone(e.target.value)}
                  value={phone}
                />
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput3"
              >
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="name@example.com"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  required
                />
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  onChange={(e) => setMsg(e.target.value)}
                  value={msg}
                />
              </Form.Group>
            </Form>

            <Button type="submit" onClick={handleSubmit}>
              Submit
            </Button>
          </Col>
        </Row>
      </Container>

      <section className="jumbotron  position-relative">
        <iframe
          width="100%"
          height="350px"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5930.2590219232625!2d-87.695793!3d41.997496!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880fd1dfbac53db1%3A0x6245115a7c6e0276!2sUru-Swati!5e0!3m2!1sen!2sin!4v1692437725029!5m2!1sen!2sin"
        ></iframe>
      </section>
    </React.Fragment>
  );
};

export default Contact;
