import React from "react";
import AboutBanner from "../images/About-Banner.jpg";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Catering = () => {
  return (
    <React.Fragment>
      <section className="jumbotron  position-relative">
        <img src={AboutBanner} alt="About" className="img-fluid w-100" />
        <div className="position-absolute top-50 start-50 translate-middle ">
          <div className="banner">
            <h2>
              <span>Catering</span>
            </h2>
            <ul className="page-title-link">
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/">Catering</a>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <Container className="py-4">
        <Row>
          <Col md="12">
            <p>
              Vishal, who has studied Sivananda yoga, continues, “There is no
              alcohol and no meat and no eggs; those kind of tamastic
              [non-health-promoting] foods are not part of our kitchen in any
              way. The energy of the kitchen is very pure and very clean. It’s
              very sattvic [beneficial to the body and mind], very pure.”
            </p>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Catering;
