import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AboutBanner from "../images/About-Banner.jpg";
import AboutImg from "../images/about_01.jpg";
import wwtw from "../images/wttw.jpg";

import "./About.css";
import Figure from "react-bootstrap/Figure";
import Card from "react-bootstrap/Card";
import Testimonials from "./Testimonials";

const About = () => {
  useEffect(() => {
    document.title = "About - Uruswati";
  }, []);
  return (
    <React.Fragment>
      <section className="jumbotron  position-relative">
        <img src={AboutBanner} alt="About" className="img-fluid w-100" />
        <div className="position-absolute top-50 start-50 translate-middle ">
          <div className="banner">
            <h2>
              <span>About</span>
            </h2>
            <ul className="page-title-link">
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/">About</a>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <Container className="py-4">
        <Row>
          <Col md="9">
            <h2>Welcome to Uru Swati</h2>
            <p>
              Uru-Swati meaning the “Morning star of peace” is a family oriented
              comfortable, 14-table North and South Indian full service
              vegetarian restaurant serving food that is fresh and of the
              highest quality.
            </p>

            <p>
              Uru Swati offers a menu of flavorful, vegetarian Indian dishes in
              one of the most culturally diverse neighborhoods in Chicago. You
              will find traditional samosas, dosa, masala, and paneer, as well
              as house-made naan and garlic breads.
            </p>

            <p>
              The recipes come from owner Hansa Chhabria. She and her husband
              also own Raj Darbar, a North Indian restaurant in Lincoln Park.
              But Uru-Swati, which opened in late May, is strictly vegetarian.
              As for the claim of “healing through food,” Hansa’s son, Vishal
              Chhabria, explains, “We use Indian spices which in ayurvedic
              science are proven to be helpful in aiding digestion and have
              antiseptic properties.”
            </p>

            <p>
              Vishal, who has studied Sivananda yoga, continues, “There is no
              alcohol and no meat and no eggs; those kind of tamastic
              [non-health-promoting] foods are not part of our kitchen in any
              way. The energy of the kitchen is very pure and very clean. It’s
              very sattvic [beneficial to the body and mind], very pure.”
            </p>

            <Card>
              <Card.Body>
                <blockquote className="blockquote mb-0 d-flex justify-content-start align-items-center">
                  <Figure className="mb-0 pb-0">
                    <Figure.Image
                      alt="about"
                      src={wwtw}
                      className="mb-0 pb-0"
                    />
                  </Figure>

                  <p>
                    Voted by Chicagoland's readers: Best Vegetarian Restaurant
                    <br />
                    in Chicago Reader, 2008!!!
                  </p>
                </blockquote>
              </Card.Body>
            </Card>
          </Col>
          <Col md="3">
            <Figure>
              <Figure.Image alt="about" src={AboutImg} />
            </Figure>
          </Col>
        </Row>
      </Container>

      <section id="testimonials_bg" className="py-4">
        <Container>
          <Row>
            <Col lg="12" className="py-0">
              <Testimonials />
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default About;
