import React from "react";
import AboutBanner from "../images/About-Banner.jpg";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Figure from "react-bootstrap/Figure";
import Card from "react-bootstrap/Card";
import ReviewsImg from "../images/reviews_banner.jpg";
import { BsFillChatQuoteFill } from "react-icons/bs";

const Reviews = () => {
  return (
    <React.Fragment>
      <section className="jumbotron  position-relative">
        <img src={AboutBanner} alt="About" className="img-fluid w-100" />
        <div className="position-absolute top-50 start-50 translate-middle ">
          <div className="banner">
            <h2>
              <span>Reviews</span>
            </h2>
            <ul className="page-title-link">
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/">Reviews</a>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <Container className="py-4">
        <Row>
          <Col md="9">
            <Card className="bg-light mb-4">
              <Card.Body>
                <div className="quote">
                  <p>
                    The food here is very good, and it's cheap. But sometime the
                    service can be quite slow. It was my Indian friend who
                    introduced me to this place and he said this is very
                    authentic. My favorite food here is snacks. Like Dahi Batata
                    Puri or Bhel. I have tried the palak paneer, and it's
                    delicious! I'd definitely recommend this place!
                  </p>
                  <h6 className="fw-bold">
                    <BsFillChatQuoteFill className="text-danger" /> &nbsp;
                    Tiratat P. Chicago, IL
                  </h6>
                </div>
              </Card.Body>
            </Card>

            <Card className="bg-light mb-4">
              <Card.Body>
                <div className="quote">
                  <p>
                    Being a non-veg, we were skeptical at first on if we'd like
                    this place. But oh boy! What a delicious find (Thanks
                    groupon!), I'm a frequent visitor of Devon, but never tried
                    this place before. With the groupon, we got the Paper Masala
                    Dosa along with other dishes (which tells you that the dosa
                    was a hit!). It was a perfect combination of potatoes,
                    spices and dipped into the sambar..man oh man was it good.
                    I've returned to this place 3 times since then just for the
                    dosa, and haven't been disappointed. Highly recommended! .
                    Random fact: this place has the coolest decor! The service
                    can be a bit slow at times but they are so genuine and nice,
                    I don't mind that!
                  </p>
                  <h6 className="fw-bold">
                    <BsFillChatQuoteFill className="text-danger" /> &nbsp; Amy
                    H. Chicago, IL
                  </h6>
                </div>
              </Card.Body>
            </Card>

            <Card className="bg-light mb-4">
              <Card.Body>
                <div className="quote">
                  <p>
                    I love this place. Be prepared for service that is not what
                    you would call good service by US standards. But the Dosa is
                    very good and the Sambar is the best I ever had.
                  </p>
                  <h6 className="fw-bold">
                    <BsFillChatQuoteFill className="text-danger" /> &nbsp; Aaron
                    S. Chicago, IL
                  </h6>
                </div>
              </Card.Body>
            </Card>

            <Card className="bg-light mb-4">
              <Card.Body>
                <div className="quote">
                  <p>
                    Really great food with okay service. We had the Mysore
                    masala dosa - a potato filling wrapped in a thin bread with
                    spicy sauce - which was quite good. We also ordered paneer
                    bhutji - a hot stew with an almost salsa-like consistency
                    made of cheese, tomato, and possibly eggplant. We also had a
                    side order of puri, a friend airy bread that came with not
                    nearly enough yogurt dip. Ordering can be a challenge for
                    those new to Indian food. Our pronunciation of the dishes
                    was so bad our server couldn't understand what we were
                    ordering and we wound up pointing at the menu. The menu
                    descriptions are not very clear on what to expect and the
                    server struggled to answer questions in English. You need to
                    be willing to jump in with both feet and order things
                    without knowing exactly what you're going to get.
                    (Thankfully, everything is vegetarian.) Based on our
                    experience, you won't go wrong so pick vaguely at random
                    from different sections of the menu at about 1 dish per
                    person and enjoy your feast.
                  </p>
                  <h6 className="fw-bold">
                    <BsFillChatQuoteFill className="text-danger" /> &nbsp;
                    Hannah C. Chicago, IL
                  </h6>
                </div>
              </Card.Body>
            </Card>

            <Card className="bg-light mb-4">
              <Card.Body>
                <div className="quote">
                  <p>
                    If you have some time in your schedule and are craving a
                    tasty and home-cooked vegetarian Indian meal, this is the
                    place. They do not do the traditional buffet style but
                    rather fresh off the menu. I enjoy their samosas, parathas,
                    and letil/vegetable dishes. Service is slow so don't go here
                    if you are in a time crunch.
                  </p>
                  <h6 className="fw-bold">
                    <BsFillChatQuoteFill className="text-danger" /> &nbsp; Mihir
                    G. Chicago, IL
                  </h6>
                </div>
              </Card.Body>
            </Card>

            <Card className="bg-light mb-4">
              <Card.Body>
                <div className="quote">
                  <p>
                    Everything is delicious- we usually order take-out/delivery
                    from here and it always arrives very hot (live around 2
                    miles away) and exactly when Grubhub predicts (within 60-90
                    minutes of placing the order). I haven't had anything I did
                    not enjoy here- everything is very flavorful and the spices
                    taste fresh.
                  </p>

                  <p>
                    Things I've tried: malai kofta, rajma masala, mutter paneer,
                    naan, alu gobi, tadka dal, palak paneer and samosas. Can't
                    wait to try more!
                  </p>

                  <h6 className="fw-bold">
                    <BsFillChatQuoteFill className="text-danger" /> &nbsp; Mihir
                    Charlotte F. Chicago, IL
                  </h6>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md="3">
            <Figure className="img-fluid w-100">
              <Figure.Image
                alt="about"
                src={ReviewsImg}
                className="img-fluid w-100"
              />
            </Figure>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Reviews;
