import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import NavbarComp from "./components/NavbarComp";

function App() {
  return (
    <React.Fragment>
      <NavbarComp sticky="top" />
    </React.Fragment>
  );
}

export default App;
