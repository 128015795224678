import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import "./NavbarComp.css";
import { FaFacebookF, FaSitemap, FaHome } from "react-icons/fa";
import { BiEnvelope } from "react-icons/bi";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Logo from "../logo.png";
import Home from "./Home";
import About from "./About";
import Footer from "./Footer";
import Reviews from "./Reviews";
import Catering from "./Catering";
import Contact from "./Contact";
import Desserts from "./Menu";
import Sitemap from "./Sitemap";
import AOS from "aos";
import "aos/dist/aos.css";

const NavbarComp = () => {
  useEffect(() => {
    AOS.init({ duration: 3000 });
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <React.Fragment>
      <Router>
        <header>
          <div className="top-bar">
            <Container>
              <div className="d-flex justify-content-between align-item-center p-1">
                <div>
                  <p className="mb-0 text-white small">
                    Call Now: (773) 381-1010
                  </p>
                </div>
                <div className="social-box">
                  <ul>
                    <li>
                      <a href="mailto:uruswati7@gmail.com">
                        <BiEnvelope /> uruswati7@gmail.com
                      </a>
                    </li>
                    <li>
                      <a href="/">
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <Nav.Link
                        as={Link}
                        to="/Sitemap"
                        activeclassname=""
                        eventKey="/Sitemap"
                        onClick={scrollToTop}
                      >
                        <FaSitemap />
                      </Nav.Link>
                    </li>
                  </ul>
                </div>
              </div>
            </Container>
          </div>
        </header>

        <div className=" sticky-top">
          {["md"].map((expand) => (
            <Navbar
              collapseOnSelect
              sticky="top"
              key={expand}
              expand={expand}
              className="bg-white stborder-bottom sticky-top"
            >
              <Container>
                <Navbar.Brand as={Link} to="/" data-aos="fade-right">
                  <img src={Logo} alt="Uru-Swati" width={100} />
                  <span className="tagline"> Healing Through Food</span>
                </Navbar.Brand>
                <Navbar.Toggle
                  aria-controls={`offcanvasNavbar-expand-${expand}`}
                />
                <Navbar.Offcanvas
                  id={`offcanvasNavbar-expand-${expand}`}
                  aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                  placement="end"
                >
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title
                      id={`offcanvasNavbarLabel-expand-${expand}`}
                    >
                      Offcanvas
                    </Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <Nav
                      className="justify-content-end flex-grow-1 pe-3 align-item-center"
                      data-aos="fade-left"
                    >
                      <Nav.Link
                        as={Link}
                        to="/"
                        activeclassname="active"
                        eventKey="/"
                        onClick={scrollToTop}
                      >
                        <FaHome />
                      </Nav.Link>

                      <Nav.Link
                        as={Link}
                        to="/About"
                        activeclassname="active"
                        eventKey="/About"
                        onClick={scrollToTop}
                      >
                        About
                      </Nav.Link>

                      <Nav.Link
                        as={Link}
                        to="/Menu"
                        activeclassname="active"
                        eventKey="/Menu"
                        onClick={scrollToTop}
                      >
                        Menu
                      </Nav.Link>

                      <Nav.Link
                        as={Link}
                        to="/Reviews"
                        activeclassname="active"
                        eventKey="/Reviews"
                        onClick={scrollToTop}
                      >
                        Reviews
                      </Nav.Link>
                      <Nav.Link
                        as={Link}
                        to="/Catering"
                        activeclassname="active"
                        eventKey="/Catering"
                        onClick={scrollToTop}
                      >
                        Catering
                      </Nav.Link>

                      <Nav.Link
                        as={Link}
                        to="/Contact"
                        activeclassname="active"
                        eventKey="/Contact"
                        onClick={scrollToTop}
                      >
                        Contact
                      </Nav.Link>
                    </Nav>
                    <Button
                      as={Link}
                      variant="danger"
                      to="https://orderonlinemenu.com/uruswati"
                      target="_blank"
                      data-aos="fade-left"
                    >
                      Order Online
                    </Button>
                  </Offcanvas.Body>
                </Navbar.Offcanvas>
              </Container>
            </Navbar>
          ))}
        </div>

        <Routes>
          <Route path="/" exact element={<Home />}></Route>
          <Route path="/About" exact element={<About />}></Route>
          <Route path="/Reviews" exact element={<Reviews />}></Route>
          <Route path="/Catering" exact element={<Catering />}></Route>
          <Route path="/Contact" exact element={<Contact />}></Route>
          <Route path="/Menu" exact element={<Desserts />}></Route>
          <Route path="/Sitemap" exact element={<Sitemap />}></Route>
        </Routes>

        <Footer />
      </Router>
    </React.Fragment>
  );
};

export default NavbarComp;
