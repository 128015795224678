import { useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import SliderOne from "../images/slider_01.jpg";
import SliderTwo from "../images/slider_02.jpg";
import SliderThree from "../images/slider_03.jpg";
import SliderFour from "../images/slider_04.jpg";
import SliderFive from "../images/slider_05.jpg";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AboutImage from "../images/About-img.jpg";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import ImgThumb01 from "../images/ourmenu-img-01.jpg";
import ImgThumb02 from "../images/ourmenu-img-02.jpg";
import ImgThumb03 from "../images/ourmenu-img-03.jpg";
import ImgThumb04 from "../images/ourmenu-img-04.jpg";
import AOS from "aos";
import "aos/dist/aos.css";

import "./Home.css";
import Testimonials from "./Testimonials";

const Home = () => {
  useEffect(() => {
    AOS.init({
      useClassNames: true,
      initClassName: false,
      animatedClassName: "animated",
    });
  }, []);

  useEffect(() => {
    document.title = "Home - Uruswati";
  }, []);

  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <Carousel
        data-bs-theme="white"
        activeIndex={index}
        onSelect={handleSelect}
      >
        <Carousel.Item>
          <img className="d-block w-100" src={SliderOne} alt="First slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={SliderTwo} alt="First slide" />
          {/* <Carousel.Caption>
            <h5>Second slide label</h5>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </Carousel.Caption> */}
        </Carousel.Item>

        <Carousel.Item>
          <img className="d-block w-100" src={SliderThree} alt="First slide" />
        </Carousel.Item>

        <Carousel.Item>
          <img className="d-block w-100" src={SliderFour} alt="First slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={SliderFive} alt="First slide" />
        </Carousel.Item>
      </Carousel>

      <section
        className="py-5"
        id="box_section"
        data-aos="fade-up"
        data-aos-duration="3000"
        data-aos-delay="250"
      >
        <Container className="px-4 px-lg-5">
          <Row className="pe-2">
            <Col
              lg="6"
              className="d-flex align-items-center home_about_section "
            >
              <div className="text-center message-box">
                <h2>Our Story</h2>
                <h1 className="">About Uru Swati</h1>
                <p>
                  Uru-Swati meaning the “Morning star of peace” is a family
                  oriented comfortable, 14-table North and South Indian full
                  service vegetarian restaurant serving food that is fresh and
                  of the highest quality.
                </p>
                <Button variant="danger">Read More</Button>
              </div>
            </Col>

            <Col lg="6">
              <img src={AboutImage} alt="About" className="img-fluid w-100" />
            </Col>
          </Row>
        </Container>
      </section>

      <section id="ourmenubg" data-aos="fade-up" data-aos-duration="5000">
        <div className="menu_border"></div>

        <Container className="py-5" data-aos="fade-up">
          <Row className="align-items-center h-100 bg-white g-0">
            <Col md="3">
              <img
                src={ImgThumb01}
                alt="ImgThumb01"
                className="img-fluid w-100"
              />
            </Col>
            <Col md="3" className="text-center">
              <h2> Our Menu</h2>
              <p>Dine In - Takeout - Delivery - Catering</p>
              <Button variant="danger">View Menu</Button>
            </Col>
            <Col md="3">
              <img
                src={ImgThumb02}
                alt="ImgThumb02"
                className="img-fluid w-100"
              />
            </Col>
            <Col md="3" className="text-center">
              <h2> Order Online</h2>
              <p>Giving your Hunger a new Option Order Online Now</p>
              <Button variant="danger">Order Online</Button>
            </Col>
            <Col md="3" className="text-center">
              <h2> Opening Hours</h2>
              <p>
                Mon, Wed, Thu, Sun: 11:30am - 9:30pm
                <br />
                Fri & Sat: 11:30am - 10:30pm
                <br />
                Tuesday Closed
              </p>
              <Button variant="danger">Read More</Button>
            </Col>
            <Col md="3">
              <img
                src={ImgThumb03}
                alt="ImgThumb03"
                className="img-fluid w-100"
              />
            </Col>
            <Col md="3" className="text-center">
              <div className="p-3">
                <h2>Contact</h2>
                <p>
                  2629 W Devon Avenue, Chicago, IL 60659
                  <br />
                  Email: uruswati7@gmail.com
                  <br />
                  Phone: (773) 381-1010
                </p>

                <Button variant="danger">Read More</Button>
              </div>
            </Col>
            <Col md="3">
              <img
                src={ImgThumb04}
                alt="ImgThumb04"
                className="img-fluid w-100"
              />
            </Col>
          </Row>
        </Container>

        <div className="menu_border_bottom"></div>
      </section>

      <section id="testimonials" data-aos="fade-up" data-aos-duration="5000">
        <Testimonials />
      </section>
    </>
  );
};

export default Home;
