import React from "react";
import Slider from "react-slick";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./Testimonials.css";
var settings = {
  arrows: true,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
const Testimonials = () => {
  return (
    <React.Fragment>
      <Container className="py-4">
        <Row>
          <Col lg="12" md="12" className="text-center mb-3">
            <div className="section-title">
              <h1>Testimonials</h1>
            </div>

            <Slider {...settings}>
              <div className="testimonial px-2">
                <h4 className="mb-1">Awesome Services!</h4>
                <p className="small fst-italic">
                  Being a non-veg, we were skeptical at first on if we'd like
                  this place. But oh boy! What a delicious find (Thanks
                  groupon!), I'm a frequent visitor of Devon, but never tried
                  this place before. With the groupon, we got the Paper Masala
                  Dosa along with other dishes (which tells you that the dosa
                  was a hit!). It was a perfect combination of potatoes, spices
                  and dipped into the sambar..man oh man was it good. I've
                  returned to this place 3 times since then just for the dosa,
                  and haven't been disappointed. Highly recommended! . Random
                  fact: this place has the coolest decor! The service can be a
                  bit slow at times but they are so genuine and nice, I don't
                  mind that!
                </p>
                <div className="testi-meta">
                  <h4>
                    Amy H.<small>- Chicago, IL</small>
                  </h4>
                </div>
              </div>

              <div className="testimonial px-2">
                <h4 className="mb-1"> Wonderful Support!</h4>
                <p className="small fst-italic">
                  The food here is very good, and it's cheap. But sometime the
                  service can be quite slow. It was my Indian friend who
                  introduced me to this place and he said this is very
                  authentic. My favorite food here is snacks. Like Dahi Batata
                  Puri or Bhel. I have tried the palak paneer, and it's
                  delicious! I'd definitely recommend this place!
                </p>
                <div className="testi-meta">
                  <h4>
                    Tiratat P.<small>- Chicago, IL</small>
                  </h4>
                </div>
              </div>

              <div className="testimonial  px-2">
                <h4 className="mb-1">Awesome Services!</h4>
                <p className="small fst-italic">
                  I love this place. Be prepared for service that is not what
                  you would call good service by US standards. But the Dosa is
                  very good and the Sambar is the best I ever had.
                </p>
                <div className="testi-meta">
                  <h4>
                    Aaron S. <small>- Chicago, IL</small>
                  </h4>
                </div>
              </div>

              <div className="testimonial  px-2">
                <h4 className="mb-1">Great & Talented Team!</h4>
                <p className="small fst-italic">
                  If you have some time in your schedule and are craving a tasty
                  and home-cooked vegetarian Indian meal, this is the place.
                  They do not do the traditional buffet style but rather fresh
                  off the menu. I enjoy their samosas, parathas, and
                  letil/vegetable dishes. Service is slow so don't go here if
                  you are in a time crunch.
                </p>
                <div className="testi-meta">
                  <h4>
                    Mihir G.<small>- Chicago, IL</small>
                  </h4>
                </div>
              </div>

              <div className="testimonial  px-2">
                <h4 className="mb-1">Great & Talented Team!</h4>
                <p className="small fst-italic">
                  Everything is delicious- we usually order take-out/delivery
                  from here and it always arrives very hot (live around 2 miles
                  away) and exactly when Grubhub predicts (within 60-90 minutes
                  of placing the order). I haven't had anything I did not enjoy
                  here- everything is very flavorful and the spices taste fresh.
                </p>
                <div className="testi-meta">
                  <h4>
                    Charlotte F.<small>- Chicago, IL</small>
                  </h4>
                </div>
              </div>
            </Slider>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Testimonials;
